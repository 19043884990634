import React from "react";
import { Row, Col } from "reactstrap";

const BoxPatterSection = ({ children, overlay }) => {
  return (
    <Col xs="12" className="h-100 patternSection">
      <Row className={`patternSectionOverlay${overlay}`}>
        <Col>
          {children}
        </Col>
      </Row>
    </Col>
  );
};

export default BoxPatterSection;
