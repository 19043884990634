import axios from 'axios'
import { toast } from "react-toastify"
import { logout } from 'redux/store/apps/auth'
import { userInfo } from 'redux/store/apps/user'
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next"

const axiosConfig = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true
});

const AxiosInterceptor = ({ children }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch();
 
  axiosConfig.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    
    if(response.data.code === 410){
      dispatch(logout());
      //toast.error(t('general.sessionHasExpired'));
    }

    return response;
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.log(error)
    return Promise.reject(error);
  });

  return children;
}


export default axiosConfig;
export { AxiosInterceptor }
