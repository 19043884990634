import React, { useState, useRef } from 'react'
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import { Row, Col, Label } from 'reactstrap'
import { CgLogOff, CgUser } from "react-icons/cg";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { logout } from 'redux/store/apps/auth'
import ConfirmModal from "components/common/modals/confirmModal";
import { useNavigate } from "react-router-dom";

const InitialsBadge = ({ user }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  const userLogout = () => {
    dispatch(logout())
  }

  const navTo = (to) => {
    navigate('/user')
    setShow(false);
  }
 
  return (
    <div ref={ref}>
      <div
        className="d-flex justify-content-center align-items-center cursor-pointer hover-scale"
        style={{
          border: '2px solid var(--upTech_blue)',
          borderRadius: '50%',
          height: '35px',
          width: '35px',
          backgroundColor: 'var(--upTech_black)',
          color: 'var(--light)'
        }}
        onClick={handleClick}
      >
        {user?.firstname.charAt(0).toUpperCase() + user?.lastname.charAt(0).toUpperCase()}
      </div>
      <Overlay
        show={show}
        target={target}
        placement="bottom-end"
        container={ref}
        containerPadding={20}
        transition={true}
        rootClose={true}
        onHide={() => setShow(false)}
      >
        <Popover id="popover-contained" >
          <Popover.Body className="py-3 px-3 path-button" style={{ borderRadius: 'none !important'}}>
            <Row className="mb-1 d-flex align-items-center cursor-pointer" onClick={() => navTo('/user')}>
              <Col>
                <Label className="m-0 p-0 cursor-pointer">
                  {t("general.userInfo")}
                </Label>
              </Col>
              <Col className="d-flex justify-content-end">
                <CgUser
                  size="20"
                  color={'var(--dark)'}
                  className="cursor-pointer hover-scale"
                />
              </Col>
            </Row>
            <ConfirmModal
              onConfirm={userLogout}
              onCancel={() => { return null }}
              bodyText={t("general.confirmLogout")}
              headerText={t("general.logout")}
            >
              <Row className="d-flex align-items-center cursor-pointer">
                <Col>
                  <Label className="m-0 p-0 text-danger cursor-pointer">
                    {t("general.logout")}
                  </Label>
                </Col>
                <Col className="d-flex justify-content-end">
                  <CgLogOff
                    size="20"
                    color={'var(--danger)'}
                    className="cursor-pointer hover-scale"
                  />
                </Col>
              </Row>
            </ConfirmModal>
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>
  )
}

export default InitialsBadge;