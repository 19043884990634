import React from "react";
import { Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import BoxPatterSection from "components/common/section/boxPatternSection";
import DotsText from "components/common/structure/dotsText";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <>
      <Row className="footer">
        <BoxPatterSection>
          <Row >
            <Col className="py-5 d-flex justify-content-center" xs="12" sm="6" lg="3">
              <Row className="w-100 h-fit-content" >
                <Col xs="12" className=".h-fit-content d-flex justify-content-center">
                  <DotsText text={t('general.information')} footer={true}/>
                </Col>
                <Col xs="12">
                  <Row className="d-flex justify-content-center">
                    <Col className="d-flex justify-content-center h-fit-content">
                      <div className="d-flex flex-column footer-column-list text-8 weight-300 mt-2">
                        <span className="py-2">{t('general.aboutUs')}</span>
                        <span className="py-2">{t('general.blog')}</span>
                        <span className="py-2">{t('general.privacyPolicy')}</span>
                        <span className="py-2">{t('general.termsConditions')}</span>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col className="py-5 d-flex justify-content-center" xs="12" sm="6" lg="3">
              <Row className="w-100 h-fit-content" >
                <Col xs="12" className="h-fit-content d-flex justify-content-center">
                  <DotsText text={t('general.support')} footer={true}/>
                </Col>
                <Col xs="12">
                  <Row className="d-flex justify-content-center">
                    <Col className="d-flex justify-content-center h-fit-content">
                      <div className="d-flex flex-column footer-column-list mt-2 text-8 weight-300">
                        <span className="py-2">{t('general.contactUs')}</span>
                        <span className="py-2">{t('general.supportCentre')}</span>
                        <span className="py-2">{t('general.trackOrder')}</span>
                        <span className="py-2">{t('general.faqs')}</span>
                        <span className="py-2">{t('general.returnPolicy')}</span>
                        <span className="py-2">{t('general.financeInformation')}</span>
                        <span className="py-2">{t('general.WarrentyInformation')}</span>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col className="py-5 d-flex justify-content-center" xs="12" sm="6" lg="3">
              <Row className="w-100 h-fit-content">
                <Col xs="12" className="h-fit-content d-flex justify-content-center">
                  <DotsText text={t('general.myAccount')} footer={true}/>
                </Col>
                <Col xs="12">
                  <Row className="d-flex justify-content-center">
                    <Col className="d-flex justify-content-center h-fit-content">
                      <div className="d-flex flex-column footer-column-list text-8 weight-300 mt-2">
                        <span className="py-2">{t('general.login')}</span>
                        <span className="py-2">{t('general.register')}</span>
                        <span className="py-2">{t('general.orders')}</span>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col className="py-5 d-flex justify-content-center" xs="12" sm="6" lg="3">
              <Row className="w-100 h-fit-content">
                <Col xs="12" className="h-fit-content d-flex justify-content-center">
                  <DotsText text={t('general.followUs')} footer={true}/>
                </Col>
                <Col xs="12">
                  <Row className="d-flex justify-content-center">
                    <Col className="d-flex justify-content-center h-fit-content">
                      <div className="d-flex flex-column footer-column-list text-8 weight-300 mt-2">
                        <span className="py-2">{t('general.aboutUs')}</span>
                        <span className="py-2">{t('general.blog')}</span>
                        <span className="py-2">{t('general.privacyPolicy')}</span>
                        <span className="py-2">{t('general.termsConditions')}</span>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </BoxPatterSection>
      </Row>
      <Row>
        <Col xs="12" className="reserved-rights d-flex align-items-center justify-content-center py-3 text-10 weight-300">
          <span>© 2022 Up Tech Box – All Rights Reserved</span>
        </Col>
      </Row>
    </>
  )
};

export default Footer;
