import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'utils/apiHelper';

// ** User Info
export const userInfo = createAsyncThunk('user/info', async (params, { getState, dispatch }) => {
  const response = await api.get('accountInfo')
  return response.data;
})

// ** Edit User Info
export const updateUser = createAsyncThunk('user/updateUser', async (params, { getState, dispatch }) => {
  console.log('params', params)
  const config = { headers: { "Content-Type": "text/plain" }};
  const response = await api.post('accountedit', {
    email: params.values.email,
    password: params.values.password,
    firstName: params.values.firstName,
    lastName: params.values.lastName,
    birthday: params.values.birth,
    gender: params.values.gender,
    password: params.password
  }, config)
  dispatch(userInfo())
  return response.data;
})

// ** Edit UserPassword
export const updateUserPassword = createAsyncThunk('user/updateUserPassword', async (params, { getState, dispatch }) => {
  console.log("params",params)
  const config = { headers: { "Content-Type": "text/plain" }};
  const response = await api.post('accountedit', {
    email: params.values.email,
    firstName: params.values.firstName,
    lastName: params.values.lastName,
    gender: params.values.gender,
    password: params.values.password,
    new_password: params.values.new_password
  }, config)
  dispatch(userInfo())
  return response.data;
})

export const appAuthSlice = createSlice({
  name: 'user',
  initialState: {
    userData: [],
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(userInfo.fulfilled, (state, action) => {
      state.userData = action.payload.psdata
    })
  }
})

export default appAuthSlice.reducer