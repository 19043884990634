import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'utils/apiHelper';

// ** Register
export const register = createAsyncThunk('auth/register', async (params, { getState, dispatch }) => {
  const config = { headers: { "Content-Type": "text/plain" }};
  const response = await api.post('register', {
    email: params.email,
    password: params.password,
    firstName: params.firstName,
    lastName: params.lastName
  }, config)
  return response.data;
})

// ** Login
export const login = createAsyncThunk('auth/login', async (params, { getState, dispatch }) => {
  const config = { headers: { "Content-Type": "text/plain" } };
  const response = await api.post('login', {
    email: params.email,
    password: params.password
  }, config)

  return response.data;
})

// ** Logout
export const logout = createAsyncThunk('auth/logout', async (params, { getState, dispatch }) => {
  console.log('executou')
  const response = await api.get('logout')
  return response.data;
})


export const appAuthSlice = createSlice({
  name: 'auth',
  initialState: {
    isLogged: false,
    loggedUser: [],
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(register.fulfilled, (state, action) => {
      state.loggedUser = action.payload
    })
    builder.addCase(login.fulfilled, (state, action) => {
      if(action.payload.code === 200){
        state.isLogged = true
      }
      state.loggedUser = action.payload.psdata.user
    })
    builder.addCase(logout.fulfilled, (state, action) => {
      state.isLogged = false
      state.loggedUser = []
    })
  }
})

export default appAuthSlice.reducer