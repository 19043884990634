import React from "react";
import { Row, Col } from "reactstrap";

const ShadowAnimation = ({ children,fullWidth }) => {
  return (
    <Row className={`m-0 p-0 ${fullWidth ? "w-100" : "w-fit-content"} h-100 dropShadow d-flex justify-content-center align-items-center`}>
      <Col className={`m-0 p-0 animation-container ${fullWidth ? "w-100" : "w-fit-content" } h-100 d-flex justify-content-center align-items-center`}>{children}</Col>
    </Row>
  );
};

export default ShadowAnimation;
