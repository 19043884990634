import React, { useEffect,useState } from "react"
import { Router } from "./router";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import {updateWindowSize} from 'redux/store/apps/appConfig'
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function App({ theme }) {

  const dispatch = useDispatch()
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const updateWidth = () => {
    dispatch(updateWindowSize(window.innerWidth))
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  useEffect(() => {
    dispatch(updateWindowSize(window.innerWidth))
  },[])

  useEffect(() => {
    if (theme === 'lightTheme') {
      document.documentElement.setAttribute('data-theme','lightTheme')
    }
    else {
      document.documentElement.setAttribute('data-theme','darkTheme')
    }
  }, [theme])

  return (
    <>
      <Router />
      <ToastContainer
        limit={5}
        position="bottom-right"
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    theme: state?.appConfig?.app?.theme,
  };
};

export default connect(mapStateToProps)(App);
