import React from "react";
import { Row, Col } from "reactstrap";
import ButtonCuttedCorners from "../common/buttons/buttonCuttedCorners";
import InitialsBadge from "components/common/badges/initials";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ReactComponent as DotsWhite } from "assets/img/icons/dotsWhite.svg";
import { ReactComponent as UpTechLogo } from "assets/img/logos/UpTechBoxWhiteBoxBlackText.svg";
import ShadowAnimation from "../common/animations/shadowAnimation";
import { ReactComponent as Search } from "assets/img/icons/search.svg";
import { ReactComponent as ShoppingCart } from "assets/img/icons/shopping-cart.svg";
import { ReactComponent as User } from "assets/img/icons/user.svg";
//import { ReactComponent as Bars } from "assets/img/icons/barsWhite.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { CgLogOff,CgMenu } from "react-icons/cg";

const NavBar = ({ user, isLogged, userAuth, windowSize }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    windowSize < 992 ?
      <>
        <Row style={{ color: "var(--light)",background:"var(--dark)" }}>
          <Col xs="6">
            <ShadowAnimation>
              <UpTechLogo style={{ width: "100px" }} />
            </ShadowAnimation>
          </Col>
          <Col xs="6" className="d-flex align-items-center justify-content-end"> 
          <CgMenu size="35"/>
          </Col>
        </Row>
      </>
      :
      <>
        <Row className="d-flex align-items-center navbar p-0">
          <Col xxl="4" lg="3" className="d-flex h-100 align-items-center">
            <Row>
              <Col xs="12">
                <ShadowAnimation>
                  <Col xs="12" className="h-100 d-flex">
                    <div>
                      <DotsWhite className="h-100 m-0 p-0" />
                    </div>
                    <div>
                      <UpTechLogo style={{ width: "115px", marginLeft: "15px", cursor: "pointer" }} onClick={() => navigate('/home', { from: 'device detail page home' })} />
                    </div>
                  </Col>
                </ShadowAnimation>
              </Col>
              <Col xs="2">
              </Col>
            </Row>
          </Col>
          <Col xxl="4" lg="6" className="d-flex justify-content-center ">
            <Row className="w-100 d-flex justify-content-center">
              <Col sm="4" className="d-flex justify-content-center">
                <ButtonCuttedCorners color="light" text={t("navbar.gamingPcs")} buttonType="small" request={() => navigate('/gamingPcs', { state: { from: location?.pathname } })} />
              </Col>
              <Col sm="4" className="d-flex justify-content-center">
                <ButtonCuttedCorners color="light" text={t("navbar.workstation")} buttonType="small" />
              </Col>
              <Col sm="4" className="d-flex justify-content-center">
                <ButtonCuttedCorners color="light" text={t("navbar.accessories")} buttonType="small" />
              </Col>
            </Row>
          </Col>
          <Col xxl="4" lg="3" className="d-flex justify-content-end pe-3">
            <Col xxl="1" lg="2" className="d-flex justify-content-center align-items-center">
              <ShadowAnimation>
                <Search className="options-icons cursor-pointer" />
              </ShadowAnimation>
            </Col>
            <Col xxl="1" lg="2" className="d-flex justify-content-center align-items-center">
              <ShadowAnimation>
                <ShoppingCart className="options-icons cursor-pointer" onClick={() => navigate('/shoppingCart')} />
              </ShadowAnimation>
            </Col>
            {!isLogged ?
              <Col xxl="1" lg="2" className="d-flex justify-content-center align-items-center">
                <CgLogOff
                  size="30"
                  color={'var(--success)'}
                  className="cursor-pointer hover-scale"
                  onClick={() => navigate('/login')}
                />
              </Col>
              :
              <Col xxl="1" lg="2" className="d-flex justify-content-center align-items-center">
                <InitialsBadge user={user?.length === 0 ? userAuth : user} />
              </Col>
            }
          </Col>
        </Row>
      </>
  )
};

const mapStateToProps = (state) => {
  return {
    userAuth: state?.auth?.loggedUser,
    user: state?.user?.userData,
    isLogged: state?.auth?.isLogged,
    windowSize: state?.appConfig?.app?.windowSize,
  };
};

export default connect(mapStateToProps)(NavBar);
