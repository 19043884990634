import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import { store, persistor } from './redux/store/storeConfig/store';
import { PersistGate } from 'redux-persist/integration/react';
import './i18n';
import "./assets/sass/main.scss"
import 'bootstrap/dist/css/bootstrap.min.css';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(

  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor} suspen>
      <Suspense fallback={null}>
          <App />
      </Suspense>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();