import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";

const ToggleShadowAnimation = ({ children, request, perfectPc, id }) => {

  useEffect(() => {
    const intelDropToggleShadow = document.getElementById("home-intel-dropToggleShadow");
    const intelAnimationToggleContainer = document.getElementById("home-intel-animationToggleContainer");
    const nvidiaDropToggleShadow = document.getElementById("home-nvidia-dropToggleShadow");
    const nvidiaAnimationToggleContainer = document.getElementById("home-nvidia-animationToggleContainer");
    const amdDropToggleShadow = document.getElementById("home-amd-dropToggleShadow");
    const amdAnimationToggleContainer = document.getElementById("home-amd-animationToggleContainer");
    const dropToggleShadow1080p = document.getElementById("home-1080p-dropToggleShadow");
    const animationToggleContainer1080p = document.getElementById("home-1080p-animationToggleContainer");
    const dropToggleShadow1440p = document.getElementById("home-1440p-dropToggleShadow");
    const animationToggleContainer1440p = document.getElementById("home-1440p-animationToggleContainer");
    const dropToggleShadowBudget = document.getElementById("home-budget-dropToggleShadow");
    const animationToggleContainerBudget = document.getElementById("home-budget-animationToggleContainer");
    if (
      intelDropToggleShadow &&
      intelAnimationToggleContainer &&
      nvidiaAnimationToggleContainer &&
      nvidiaDropToggleShadow &&
      amdAnimationToggleContainer &&
      amdDropToggleShadow &&
      dropToggleShadow1080p &&
      animationToggleContainer1080p &&
      dropToggleShadow1440p &&
      animationToggleContainer1440p &&
      dropToggleShadowBudget &&
      animationToggleContainerBudget
      ) {
      if (perfectPc?.brand?.intel) {
        intelDropToggleShadow.classList.remove("dropShadowToggle");
        intelDropToggleShadow.classList.add("dropShadowToggleActive");
        intelAnimationToggleContainer.classList.remove("animation-container-toggle")
        intelAnimationToggleContainer.classList.add("animation-container-active-toggle")
      }
      else {
        intelDropToggleShadow.classList.remove("dropShadowToggleActive");
        intelDropToggleShadow.classList.add("dropShadowToggle");
        intelAnimationToggleContainer.classList.remove("animation-container-active-toggle")
        intelAnimationToggleContainer.classList.add("animation-container-toggle")
      }
      if (perfectPc?.brand?.nvidia) {
        nvidiaDropToggleShadow.classList.remove("dropShadowToggle");
        nvidiaDropToggleShadow.classList.add("dropShadowToggleActive");
        nvidiaAnimationToggleContainer.classList.remove("animation-container-toggle")
        nvidiaAnimationToggleContainer.classList.add("animation-container-active-toggle")
      }
      else {
        nvidiaDropToggleShadow.classList.remove("dropShadowToggleActive");
        nvidiaDropToggleShadow.classList.add("dropShadowToggle");
        nvidiaAnimationToggleContainer.classList.remove("animation-container-active-toggle")
        nvidiaAnimationToggleContainer.classList.add("animation-container-toggle")
      }
      if (perfectPc?.brand?.amd) {
        amdDropToggleShadow.classList.remove("dropShadowToggle");
        amdDropToggleShadow.classList.add("dropShadowToggleActive");
        amdAnimationToggleContainer.classList.remove("animation-container-toggle")
        amdAnimationToggleContainer.classList.add("animation-container-active-toggle")
      }
      else {
        amdDropToggleShadow.classList.remove("dropShadowToggleActive");
        amdDropToggleShadow.classList.add("dropShadowToggle");
        amdAnimationToggleContainer.classList.remove("animation-container-active-toggle")
        amdAnimationToggleContainer.classList.add("animation-container-toggle")
      }
      if (perfectPc?.resolution === "1080p") {
        dropToggleShadow1080p.classList.remove("dropShadowToggle");
        dropToggleShadow1080p.classList.add("dropShadowToggleActive");
        dropToggleShadow1440p.classList.remove("dropShadowToggleActive");
        dropToggleShadow1440p.classList.add("dropShadowToggle");
        animationToggleContainer1080p.classList.remove("animation-container-toggle");
        animationToggleContainer1080p.classList.add("animation-container-active-toggle");
        animationToggleContainer1440p.classList.remove("animation-container-active-toggle");
        animationToggleContainer1440p.classList.add("animation-container-toggle");
      }
      else if (perfectPc?.resolution === "1440p") {
        dropToggleShadow1080p.classList.remove("dropShadowToggleActive");
        dropToggleShadow1080p.classList.add("dropShadowToggle");
        dropToggleShadow1440p.classList.remove("dropShadowToggle");
        dropToggleShadow1440p.classList.add("dropShadowToggleActive");
        animationToggleContainer1080p.classList.remove("animation-container-active-toggle");
        animationToggleContainer1080p.classList.add("animation-container-toggle");
        animationToggleContainer1440p.classList.remove("animation-container-toggle");
        animationToggleContainer1440p.classList.add("animation-container-active-toggle");
      }
      else {
        dropToggleShadow1080p.classList.remove("dropShadowToggleActive");
        dropToggleShadow1080p.classList.add("dropShadowToggle");
        dropToggleShadow1440p.classList.remove("dropShadowToggleActive");
        dropToggleShadow1440p.classList.add("dropShadowToggle");
        animationToggleContainer1080p.classList.remove("animation-container-active-toggle");
        animationToggleContainer1080p.classList.add("animation-container-toggle");
        animationToggleContainer1440p.classList.remove("animation-container-active-toggle");
        animationToggleContainer1440p.classList.add("animation-container-toggle");
      }
      if(perfectPc?.budget !== 0){
        dropToggleShadowBudget.classList.remove("dropShadowToggle");
        dropToggleShadowBudget.classList.add("dropShadowToggleActive");
        animationToggleContainerBudget.classList.remove("animation-container-toggle");
        animationToggleContainerBudget.classList.add("animation-container-active-toggle");
      }
      else{
        dropToggleShadowBudget.classList.remove("dropShadowToggleActive");
        dropToggleShadowBudget.classList.add("dropShadowToggle"); 
        animationToggleContainerBudget.classList.remove("animation-container-active-toggle");
        animationToggleContainerBudget.classList.add("animation-container-toggle");
      }
    }
  }, [perfectPc])
  

  return (
    <Row id={`${id}-dropToggleShadow`} className="m-0 p-0 dropShadowToggle d-flex w-fit-content justify-content-center align-items-center">
      <Col id={`${id}-animationToggleContainer`} className="m-0 p-0 position-relative animation-container-toggle w-fit-content d-flex justify-content-center align-items-center">
        <div className="position-absolute w-100 h-100" style={{ zIndex: 1 }} id={id} onClick={request} />
        {children}
      </Col>
    </Row>
  );
};

export default ToggleShadowAnimation;