import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import api from '../../../helpers/apiHelper';
// // ** Axios Imports
// import axios from 'axios'

// ** Fetch WindowSize
export const updateWindowSize = createAsyncThunk('appConfig/updateWindowSize', async (params) => { 
  return params;
})

// ** Fetch PreviousLocation
export const previousLocation = createAsyncThunk('appConfig/previousLocation', async (params) => { 
  return params;
})

export const appConfigSlice = createSlice({
  name: 'theme',
  initialState: {
    app: {
      theme : "darkTheme",
      windowSize: "",
      previousLocation: "",

    }
  },
  
  reducers: {},
  extraReducers: builder => {
    builder.addCase(updateWindowSize.fulfilled, (state, action) => {
      state.app.windowSize = action.payload
    })
    builder.addCase(previousLocation.fulfilled, (state, action) => {
      state.app.previousLocation = action.payload
    })
  }
})

export default appConfigSlice.reducer
