import React, { useState } from 'react';
import {
  Modal,
  Row,
  Col
} from 'reactstrap';
import { useTranslation } from "react-i18next";
import ButtonCuttedCorners from "components/common/buttons/buttonCuttedCorners";
import SimpleCuttedButton from "components/common/buttons/simpleCuttedButton";
import DotsText from "components/common/structure/dotsText";

const ConfirmModal = ({ bodyText, headerText, onConfirm, onCancel, children }) => {
  const { t } = useTranslation()

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const handleConfirm = () => {
    toggle();
    onConfirm();
  };

  const handleCancel = () => {
    toggle();
    onCancel();
  };

  return (
    <>
      <Modal isOpen={modal} centered toggle={toggle} className="custom-modal text-light">
        <Row className="pt-3 px-4">
          <Col md="12" className="d-flex justify-content-between align-items-center">
            <DotsText text={headerText} size="small" />
            <ButtonCuttedCorners text={t('general.close')} color="light" buttonType="small" request={handleCancel}/>
          </Col>
        </Row>
        <Row className="py-4 px-4">
          <Col>
            {bodyText}
          </Col>
        </Row>
        <Row className="pb-3 px-4">
          <Col className="d-flex justify-content-center">
            <SimpleCuttedButton text={t('general.cancel')} color="danger" type="button" onClick={handleCancel}/>
          </Col>
          <Col className="d-flex justify-content-center">
            <SimpleCuttedButton text={t('general.confirm')} color="primary" type="button" onClick={handleConfirm}/>
          </Col>
        </Row>        
      </Modal>
      { React.cloneElement( children, { onClick: (e) => {
        e.stopPropagation();
        toggle();
        }} ) }
    </>
  );
};

export default ConfirmModal;