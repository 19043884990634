import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'utils/apiHelper';


export const getAddresses = createAsyncThunk('address/Address', async (params, { getState, dispatch }) => {
  const response = await api.get('alladdresses')
  return response.data;
})

export const getAddressesById = createAsyncThunk('address/AddressById', async (params, { getState, dispatch }) => {
  const response = await api.get(`address?id_address=${params}`)
  return response.data;
})

export const addAdreess = createAsyncThunk('address/addAdreess', async (params, { getState, dispatch }) => {
  const config = { headers: { "Content-Type": "text/plain" } };
  const response = await api.post('address',params, config)
  dispatch(getAddresses())
  return response.data;
})

export const deleteAdreess = createAsyncThunk('address/deleteAdreess', async (params, { getState, dispatch }) => {
  const config = { headers: { "Content-Type": "text/plain" }};
  const response = await api.delete('address',{data : {id_address: params},config})
  dispatch(getAddresses())
  return response.data;
})

export const getCountries = createAsyncThunk('address/countries', async (params, { getState, dispatch }) => {
  const response = await api.get('addressform')
  return response.data;
})

export const appAddressSlice = createSlice({
  name: 'address',
  initialState: {
    data: [],
  },

  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAddresses.fulfilled, (state, action) => {
      state.addresses = action.payload.psdata
    })
    builder.addCase(getCountries.fulfilled, (state, action) => {
      state.countries = action.payload.psdata.countries
    })
  }
})

export default appAddressSlice.reducer