import React from "react";
import { Row, Col } from "reactstrap";
import { ReactComponent as DotsWhite } from "assets/img/icons/dotsWhite.svg";
import ShadowAnimation from "../animations/shadowAnimation";

const DotsText = ({ text, size,footer,secondText,marginZero,request }) => {
  return (
    <Row className={marginZero ? "m-0" : ""}>
      <ShadowAnimation>
        <Col className={`d-flex flex-row min-width-0 ${size === "large" ? "dots-text-large" : "dots-text"}`} onClick={request}>
          <div className={`d-flex align-items-center ${size === "large" ? "dots-text-icon-large" : "dots-text-icon"}`}>
            <DotsWhite/>
          </div>
          <div className={` my-auto truncate ${size === "large" ? "dots-text-title-large text-1 weight-300" : `dots-text-title ${footer ? "text-5" : "text-3"} weight-300`}`}>
            {text}
          </div>
          <div style={{color:"var(--upTech_grey", paddingTop:"13px"}} className={`ps-1 my-auto truncate uppercase ${size === "large" ? "dots-text-description-large text-6 weight-300" : `dots-text-description ${footer ? "text-8" : "text-6"} weight-300`}`}>
            {secondText}
          </div>
        </Col>
      </ShadowAnimation>
    </Row>
  );
};

export default DotsText;
