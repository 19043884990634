import React from "react";
import { Button } from "reactstrap";

const SimpleCuttedButton = ({ text, onClick, color, disabled, icon, type, small,textSize }) => {
  return (
    <Button 
      disabled={disabled} 
      color={color} 
      onClick={onClick} 
      className={`d-flex justify-content-center ${textSize} align-items-center path-button py-2 rounded-0 w-100 ${textSize ? 'weight-300' : ''}`} 
      type={type}
      style={{ height: small && '30px'}}
    >
      {icon && 
        <div className="me-2 pb-1">
          {icon}
        </div>
      }
      <span className="p-0 truncate">{text}</span>
    </Button>
  );
};

export default SimpleCuttedButton;
