/* eslint-disable no-else-return */
import React from 'react';
import { Outlet,Navigate } from 'react-router-dom';
import { connect } from 'react-redux';

function ProtectedRoute({loggedUser,isLogged}) {
  
  return (
    Object.entries(loggedUser).length !== 0 && isLogged  ? 
      <Outlet /> 
      : 
      <Navigate to="/login" />
  )
}
  
const mapStateToProps = state => {
  return {
      loggedUser: state?.auth?.loggedUser,
      isLogged: state?.auth?.isLogged
  }
}

export default connect(mapStateToProps)(ProtectedRoute);
