import React from "react";
import Footer from "components/footer";
import NavBar from './../components/navbar'
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";

const AppLayout = ({ children }) => {

  const location = useLocation()

  const page = location.pathname.split("/")[1]

  return (
    <div className={`d-flex w-100 h-100 appLayout ${page !== 'login' && page !== 'regist'  ? 'pt-4' : 'pt-0'}`}>
      <main className="w-100 h-100">
        {page !== 'login' && page !== 'regist' &&
          <div className="container-fluid">
            <NavBar />
          </div>
        }
        <div className="container-fluid home">
          {children}
        </div>
        {page !== 'login' && page !== 'regist' &&
          <div className="container-fluid">
            <Footer />
          </div>
        }
      </main>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    lastLocation: state?.appConfig?.app?.previousLocation,
  };
};

export default connect(mapStateToProps)(AppLayout);
